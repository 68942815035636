import { Component, OnInit } from '@angular/core';
import { NavParams, NavController } from '@ionic/angular';
import { Platform, ModalController, ToastController } from '@ionic/angular';
import { GlobalProvider } from "../../providers/global/global";
import { Chats } from '../../providers';
import { MomentjsPipe } from '../../pipes/momentjs/momentjs'

import { CollegaModalPage } from '../collega-modal/collega-modal.page';


@Component({
  selector: 'app-chatusers-modal',
  templateUrl: './chatusers-modal.page.html',
  styleUrls: ['./chatusers-modal.page.scss'],
})


export class ChatusersModalPage implements OnInit {
	
	participants: any;
	
	constructor(
		private platform: Platform,
		private modalCtrl: ModalController,
		private navParams: NavParams,
		public	globalvar: GlobalProvider,
		public momentjs: MomentjsPipe,
		public toastController: ToastController,
		public navCtrl: NavController,
		public	chats: Chats
	) { 
		let id = navParams.get('chatgroup_id');

		this.chats.getAllParticipants(id).subscribe((resp:any) => {
			this.participants = resp;
		},
		(err) => {

	    });	
	    
	    this.platform.backButton.subscribe(() => {
	       this.dismissModal();
	    });

	}
	
  	 	 
  ngOnInit() {
  }

  
  	async presentModal(user) {
		user.companies = [{'id': 0, 'name':user.companyname}]
		
		const modal = await this.modalCtrl.create({
			component: CollegaModalPage,
			componentProps: {
				'user': user
			}
		});
		return await modal.present();
	}	
  
  dismissModal() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }
}
