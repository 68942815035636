import { share } from 'rxjs/operators';
import { Observable } from "rxjs";

import { Component } from '@angular/core';

import { GlobalProvider } from "../../providers/global/global";
import { ModalController } from '@ionic/angular';

import { PrivacyPolicy} from '../../providers';


@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.page.html',
  styleUrls: ['./privacy-policy.page.scss'],
})
export class PrivacyPolicyPage{

	back: Boolean = false;
	privacypolicy_text: any = '';

  constructor(
		public	globalvar: GlobalProvider,
		public	privacyPolicy: PrivacyPolicy,
		public 	modalCtrl: ModalController	  
	  
  ) {
  		
  		if(this.globalvar.login){
	  		this.back = false;
  		}else{
	  		this.back = true;
  		}
  		this.init();
	}

  init() {
	  this.privacyPolicy.getPrivacyPolicy().subscribe((resp:any) => {
			this.privacypolicy_text = resp.privacy_policy;
		},
		(err) => {

	    });	
  }
  
    dismissPrivacyPolicy() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }

}
