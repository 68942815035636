import { Component } from '@angular/core';
import { GlobalProvider } from "../../providers/global/global";

@Component({
  selector: 'app-tabs',
  templateUrl: 'tabs.page.html',
  styleUrls: ['tabs.page.scss']
})
export class TabsPage {

  constructor(
	  	public	globalvar: GlobalProvider,
  ) {}

}
