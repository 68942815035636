import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { TabsPage } from './tabs/tabs.page';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'landing',
    pathMatch: 'full'
  },
{
    path: 'tabs',
    component: TabsPage,
    children: [
      {
        path: 'dashboard',
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./pages/dashboard/dashboard.module').then(m => m.DashboardPageModule)
          }
        ]
      },
      {
        path: 'bookings',
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./bookings/bookings.module').then(m => m.BookingsPageModule)
          }
        ]
      },
      {
        path: 'mybookings',
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./mybookings/mybookings.module').then(m => m.MybookingsPageModule)
          }
        ]
      },
      {
        path: 'collegas',
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./collegas/collegas.module').then(m => m.CollegasPageModule)
          }
        ]
      },
      {
        path: 'etages',
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./etages/etages.module').then(m => m.EtagesPageModule)
          }
        ]
      },
      {
        path: 'chats',
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./chats/chats.module').then(m => m.ChatsPageModule)
          }
        ]
      },
      {
        path: 'rooms',
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./rooms/rooms.module').then(m => m.RoomsPageModule)
          }
        ]
      },
      {
        path: 'news',
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./news/news.module').then(m => m.NewsPageModule)
          }
        ]
      },
      {
        path: 'settings',
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./settings/settings.module').then(m => m.SettingsPageModule)
          }
        ]
      },
	  {
	    path: 'profile',
	    loadChildren: () => import('./profile/profile.module').then( m => m.ProfilePageModule)
	  },
      {
        path: 'privacy-policy',
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyPageModule)
          }
        ]
      } ,
      {
        path: 'feedback',
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./feedback/feedback.module').then(m => m.FeedbackPageModule)
          }
        ]
      }  
    ]
  }, 
  {
    path: 'collega-modal',
    loadChildren: () => import('./collega-modal/collega-modal.module').then( m => m.CollegaModalPageModule)
  },
  {
    path: 'bookingresult-modal',
    loadChildren: () => import('./bookingresult-modal/bookingresult-modal.module').then( m => m.BookingresultModalPageModule)
  },
  {
    path: 'landing',
    loadChildren: () => import('./pages/landing/landing.module').then( m => m.LandingPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/auth/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/auth/register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'chatroom',
    loadChildren: () => import('./chatroom/chatroom.module').then(m => m.ChatroomPageModule)  

  },
  {
    path: 'chatusers-modal',
    loadChildren: () => import('./chatusers-modal/chatusers-modal.module').then( m => m.ChatusersModalPageModule)
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./privacy-policy/privacy-policy.module').then( m => m.PrivacyPolicyPageModule)
  },
  {
    path: 'feedback',
    loadChildren: () => import('./feedback/feedback.module').then( m => m.FeedbackPageModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
