import { share } from 'rxjs/operators';
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/operator/share';

import { Storage } from '@ionic/storage';
import { Injectable } from '@angular/core';
import { GlobalProvider } from '../../providers/global/global';

import { Api } from '../api/api';

@Injectable()
export class Bookings {
	_company: any;

	constructor(
		public api: Api,
		private storage: Storage,
		public globalvar: GlobalProvider
	) {}

	getbookings(id) {
		let seq = this.api.get('meetingscreen/bookings/' + id).share();

		seq.subscribe(
			(res: any) => {},
			(err) => {
				console.error('ERROR', err);
			}
		);

		return seq;
	}

	getCurrentMonth(room_id) {
		let seq = this.api.get('bookings/currentMonth/' + room_id).share();

		seq.subscribe(
			(res: any) => {},
			(err) => {
				console.error('ERROR', err);
			}
		);

		return seq;
	}

	getBookableRooms() {
		let seq = this.api.get('bookings/rooms').share();

		seq.subscribe(
			(res: any) => {},
			(err) => {
				console.error('ERROR', err);
			}
		);

		return seq;
	}

	getMyBookings(user_id) {
		let seq = this.api.post('bookings', { user_id: user_id }).share();

		seq.subscribe(
			async (res: any) => {
				await this.storage.set('mybookings', JSON.stringify(res)).then(
					() => {
						console.log('bookings stored');
					},
					(error) => {
						console.error('Error storing item', error);
					}
				);
			},
			(err) => {
				console.error('ERROR', err);
			}
		);

		return seq;
	}

	forDate(data) {
		let seq = this.api.post('bookings/date', data).share();

		seq.subscribe(
			(res: any) => {},
			(err) => {
				console.error('ERROR', err);
			}
		);

		return seq;
	}

	checkAvailablility(data) {
		let seq = this.api.post('bookings/check', data).share();

		seq.subscribe(
			(res: any) => {},
			(err) => {
				console.error('ERROR', err);
			}
		);

		return seq;
	}

	store(data) {
		let seq = this.api.post('bookings/new', data).share();

		seq.subscribe(
			(res: any) => {},
			(err) => {
				console.error('ERROR', err);
			}
		);

		return seq;
	}

	remove(data) {
		let seq = this.api
			.delete('bookings/remove/' + data.id + '/' + data.user_id)
			.share();

		seq.subscribe(
			(res: any) => {},
			(err) => {
				console.error('ERROR', err);
			}
		);

		return seq;
	}
}
