import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ForgottenpswdPageRoutingModule } from './forgottenpswd-routing.module';

import { ForgottenpswdPage } from './forgottenpswd.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ForgottenpswdPageRoutingModule
  ],
  declarations: [ForgottenpswdPage]
})
export class ForgottenpswdPageModule {}
