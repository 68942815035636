import { Component, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { NavParams } from '@ionic/angular';
import { LoginPage } from '../login/login.page';
import { AuthService } from 'src/app/services/auth.service';
import { NgForm } from '@angular/forms';
import { AlertService } from 'src/app/services/alert.service';
import { Platform } from '@ionic/angular';
@Component({
  selector: 'app-forgottenpswd',
  templateUrl: './forgottenpswd.page.html',
  styleUrls: ['./forgottenpswd.page.scss'],
})
export class ForgottenpswdPage implements OnInit {
 	userinfo: any;
 	idx: any;
 	page = 'vergeten';
 	spinner = false;
 
  constructor(private modalController: ModalController,
    private authService: AuthService,
    private navCtrl: NavController,
    private alertService: AlertService,
	private navParams: NavParams,
	private platform: Platform,

  ) { 
		this.userinfo = navParams.get('user');
		this.idx = navParams.get('idx');
		if(this.userinfo || this.idx){
			this.page = 'instellen';
		}else{
			this.page = 'vergeten';
		}
		
		this.platform.backButton.subscribe(() => {
			this.dismissForgottenpswd();
	    });

  
  }
  ngOnInit() {
  }

	ionViewWillEnter() {
		if(this.userinfo){
			(<HTMLInputElement>document.getElementById('emailforgot')).value = this.userinfo.email;
		}else{
		}
	}

  // Dismiss Register Modal
  dismissForgottenpswd() {
    this.modalController.dismiss();
  }
  // On Login button tap, dismiss Register modal and open login Modal

  forgottenpswd(form: NgForm) {
	 let that = this.modalController;
	let email = (<HTMLInputElement>document.getElementById('emailforgot')).value;
	this.spinner = true;
    this.authService.forgottenpswd(email).subscribe(
		data => {
			this.spinner = false;
			if(this.userinfo){
				this.alertService.presentToast('Een mail wordt naar je e-mailadres gestuurd. Hiermee kun je je wachtwoord instellen');
				that.dismiss();
			}else{
				this.alertService.presentToast('Een mail wordt naar je e-mailadres gestuurd. Hiermee kun je je wachtwoord instellen<br/>Je wordt nu teruggestuurd naar de inlogpagina');
				setTimeout(function(){
					that.dismiss();
				},5000 )
			}
		  
		},
		error => {
			this.spinner = false;
	        if(error.status == 422){
		        this.alertService.presentToast("Onvolledige gegevens");
		    }else if(error.status == 401){
				this.alertService.presentToast("E-mail onjuist");
 			}else if(error.status == 404){
				this.alertService.presentToast("E-mail onjuist");				
			}else{
				this.alertService.presentToast("Error:"+ error.status);
			}  
		}

    );
  }
}