import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { BookingresultModalPageRoutingModule } from './bookingresult-modal-routing.module';

import { BookingresultModalPage } from './bookingresult-modal.page';
import { PipesModule } from '../../pipes/pipes.module';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		IonicModule,
		BookingresultModalPageRoutingModule,
		PipesModule,
	],
	declarations: [BookingresultModalPage],
})
export class BookingresultModalPageModule {}
