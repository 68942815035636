import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Storage } from '@ionic/storage';
import { EnvService } from './env.service';
import { User } from '../models/user';
import { Companies } from 'src/providers';
import { NavController } from '@ionic/angular';
import { GlobalProvider } from 'src/providers/global/global';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	isLoggedIn = false;
	token: any;

	constructor(
		private http: HttpClient,
		private env: EnvService,
		private companies: Companies,
		private navCtrl: NavController,
		private storage: Storage,
		public globalvar: GlobalProvider,
	) {}

	login(email: String, password: String) {
		return new Promise((resolve, reject) => {
			this.http
				.post(this.env.API_URL + 'auth/login', {
					email: email,
					password: password,
				})
				.subscribe(
					(async (token) => {
						await this.storage.set('token', JSON.stringify(token)).then(
							() => {
								this.token = token;
								this.isLoggedIn = true;
								resolve(token);
							},
							(error) => {
								console.error('Fout by opslaan token', error);
								reject();
							}
						);
					}),
					(error) => {
						reject(error);
					}
				);
		});
	}
	register(fName: String, lName: String, email: String, password: String) {
		return this.http.post(this.env.API_URL + 'auth/register', {
			fName: fName,
			lName: lName,
			email: email,
			password: password,
		});
	}
	forgottenpswd(email: String) {
		return this.http.post(this.env.API_URL + 'password/create', {
			email: email,
		});
	}
	logout() {
		if (!this.token) {
			this.storage.remove('token');
			this.isLoggedIn = false;
			this.globalvar.myuser = {};
			this.storage.clear();
			this.isLoggedIn = false;
			this.navCtrl.navigateRoot('/landing');
			return;
		}
		const headers = new HttpHeaders({
			Authorization:
				this.token['token_type'] + ' ' + this.token['access_token'],
		});

		return this.http
			.get(this.env.API_URL + 'auth/logout', { headers: headers })
			.pipe(
				tap((data) => {
					this.storage.remove('token');
					this.isLoggedIn = false;
					delete this.token;
					return data;
				})
			);
	}
	user() {
		if (!this.token) {
			return;
		}
		const headers = new HttpHeaders({
			Authorization:
				this.token['token_type'] + ' ' + this.token['access_token'],
		});
		return this.http
			.get<User>(this.env.API_URL + 'auth/user', { headers: headers })
			.pipe(
				tap((user) => {
					return user;
				})
			);
	}

	getToken() {
		return this.storage.get('token').then(
			(data) => {
				data = JSON.parse(data);
				this.token = data;
				if (this.token != null) {
					if (this.token.access_token == '') {
						this.isLoggedIn = false;
					} else {
						this.isLoggedIn = true;
					}
				} else {
					this.isLoggedIn = false;
				}
			},
			(error) => {
				this.isLoggedIn = false;
			}
		);
	}
}
