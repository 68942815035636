import { Injectable, isDevMode } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { User } from 'src/app/models/user';

@Injectable()
export class GlobalProvider {
	public demanufactuur_token = null;

	public pushkey: string;
	public pushType: string;
	public pushEnabled: boolean;
	public pushNotificationEnabled: boolean;
	// 	private _isDev: boolean = ((<any>window)['IonicDevServer'] != undefined);
	public domain: string;
	public images: string;
	public api: string;
	public login: string;
	public myuser: any = { avatar: '', name: '', companyname: '' };
	public mycompany: any = { id: '', name: '' };
	public firebaseConfig: any;
	public version: string;
	public officeAFK: boolean = false;
	public storage: string;
	public socket: string;
	public currentChat: string = '';
	public totalChats: number = 0;
	public deploy: string = '1.2.3';
	public labels: any = [];
	public deviceType: '';

	constructor(public alertCtrl: AlertController) {
		this.domain = 'https://demanufactuur.toffer.io';
		this.storage = 'https://demanufactuur.toffer.io/storage/';
		this.api = 'https://demanufactuur.toffer.io';
		this.login = 'https://demanufactuur.toffer.io/api/';

		//WARNING THIS IS ALSO USED IN app.module.ts !!!!!! THESE ARE HOWEVER NOT CONNECTED
		// this.socket		= 'https://demanufactuur.nl.testappcomm.nl:5001';

		//WARNING THIS IS ALSO USED IN app.module.ts !!!!!! THESE ARE HOWEVER NOT CONNECTED
		this.socket = 'https://demanufactuur.toffer.io:5002';

		/* 
			//	TEST
				this.firebaseConfig = {
					apiKey: "AIzaSyAAiglWFATLSkGDRKfLyIzu7BlyaW9ZBuk",
					authDomain: "demanufactuur-live.firebaseapp.com",
					databaseURL: "https://demanufactuur-test.firebaseio.com",
					projectId: "demanufactuur-test",
					storageBucket: "demanufactuur-test.appspot.com",
					messagingSenderId: "975737580979",
					appId: "1:975737580979:web:0c0b7183afa112124f2fc6",
					measurementId: "G-78NY5VS7EH"
				  }
		*/
		//	LIVE
		this.firebaseConfig = {
			apiKey: 'AIzaSyAy8TJQABbtosPSNNb5b4JQ18er8hjNr74',
			authDomain: 'demanufactuur-live.firebaseapp.com',
			databaseURL: 'https://demanufactuur-live.firebaseio.com',
			projectId: 'demanufactuur-live',
			storageBucket: 'demanufactuur-live.appspot.com',
			messagingSenderId: '94126312482',
			appId: '1:94126312482:web:6e64a70f91f88026234e7b',
			measurementId: 'G-W557CE3F4V',
		};
	}

	public versionComparision(a, b) {
		console.log('versions:' + a + '  ' + b);
		if (a === b) {
			return 0;
		}

		var a_components = a.split('.');
		var b_components = b.split('.');

		var len = Math.min(a_components.length, b_components.length);

		// loop while the components are equal
		for (var i = 0; i < len; i++) {
			// A bigger than B
			if (parseInt(a_components[i]) > parseInt(b_components[i])) {
				return 1;
			}

			// B bigger than A
			if (parseInt(a_components[i]) < parseInt(b_components[i])) {
				return -1;
			}
		}

		// If one's a prefix of the other, the longer one is greater.
		if (a_components.length > b_components.length) {
			return 1;
		}

		if (a_components.length < b_components.length) {
			return -1;
		}

		// Otherwise they are the same.
		return 0;
	}
}
