import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import { GlobalProvider } from "../../providers/global/global";
import { Companies} from '../../providers';

@Component({
  selector: 'app-news-modal',
  templateUrl: './news-modal.page.html',
  styleUrls: ['./news-modal.page.scss'],
})


export class NewsModalPage implements OnInit {

	userinfo: any;

	private useredit : any;

	constructor(
		private modalCtrl: ModalController,
		private navParams: NavParams,
		public	globalvar: GlobalProvider,
		public	companies: Companies,
	) { 
		 this.userinfo = navParams.get('user');
	}

 	 
  ngOnInit() {
  }


  dismissModal() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }

}
