
import { Injectable } from '@angular/core';
import { GlobalProvider } from "../../providers/global/global";

import { Api } from '../api/api';

@Injectable()
export class Feedback {
	_Feedback: any;
	
	constructor(
		public api: Api,
		public globalvar: GlobalProvider		
	) { }


	sendFeedback(data) {
		
		let seq = this.api.post('feedback/app', data, null).share();
		
		seq.subscribe(
		(res: any) => {
			// If the API returned a successful response, mark the project as logged in

			
		},
		(err) => {
			console.error('ERROR', err);
		});
		
		return seq;
	}



}
