import { NgModule } from '@angular/core';
import { MomentjsPipe } from './momentjs/momentjs';

@NgModule({
	declarations: [
		MomentjsPipe,
    ],
	imports: [],
	exports: [
		MomentjsPipe,
    ],
    providers:    [ 
    	MomentjsPipe 
    ]

})
export class PipesModule {}
