
import { Injectable } from '@angular/core';
import { GlobalProvider } from "../../providers/global/global";

import { Api } from '../api/api';

@Injectable()
export class PrivacyPolicy {
	_PrivacyPolicy: any;
	
	constructor(
		public api: Api,
		public globalvar: GlobalProvider		
	) { }


	getPrivacyPolicy() {
		let seq = this.api.get('privacy-policy/app').share();
		
		seq.subscribe(
		(res: any) => {
			// If the API returned a successful response, mark the project as logged in

			
		},
		(err) => {
			console.error('ERROR', err);
		});
		
		return seq;
	}



}
