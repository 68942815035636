import { HttpClient, HttpClientModule } from '@angular/common/http';
import { HTTP } from '@ionic-native/http/ngx';

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { Device } from '@ionic-native/device/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { IonicStorageModule, Storage } from '@ionic/storage';
// import { FCM } from '@ionic-native/fcm/ngx';
import { Push } from '@ionic-native/push/ngx';


import { Settings, Api, Users, Companies, Etages, News , Chats, Bookings, PrivacyPolicy, Feedback} from '../providers';

import { GlobalProvider } from '../providers/global/global'; 

import { ForgottenpswdPageModule } from './pages/auth/forgottenpswd/forgottenpswd.module';
import { PrivacyPolicyPageModule } from './privacy-policy/privacy-policy.module';
import { CollegaModalPageModule } from './collega-modal/collega-modal.module';
import { NewsModalPageModule } from './news-modal/news-modal.module';
import { BookingresultModalPageModule } from './bookingresult-modal/bookingresult-modal.module';
import { ChatusersModalPageModule } from './chatusers-modal/chatusers-modal.module';


import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TabsPageModule } from './tabs/tabs.module';
import { DatePipe } from '@angular/common'


import { Crop } from '@ionic-native/crop/ngx';
//import { ImagePicker } from '@ionic-native/image-picker/ngx';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { FileTransfer, FileUploadOptions, FileTransferObject } from '@ionic-native/file-transfer/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';


import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { AppVersion } from '@ionic-native/app-version/ngx';

const config: SocketIoConfig = { url: 'https://demanufactuur.toffer.io:5002', options: {} };

export function provideSettings(storage: Storage) {
	/**
	 * The Settings provider takes a set of default settings for your app.
	 *
	 * You can add new settings options at any time. Once the settings are saved,
	 * these values will not overwrite the saved values (this can be done manually if desired).
	 */
	return new Settings(storage, {
		option1: true,
		option2: "Manufactuur storage",
		option3: "4",
		option4: "Hello",
	});
}

@NgModule({
	declarations: [AppComponent],
	entryComponents: [],
	imports: [
		BrowserModule, 
		HttpClientModule,
		IonicModule.forRoot(),
		IonicStorageModule.forRoot(),
		AppRoutingModule,
		ForgottenpswdPageModule,
		PrivacyPolicyPageModule,
		CollegaModalPageModule,
		BookingresultModalPageModule,
		ChatusersModalPageModule,
		NewsModalPageModule,
		TabsPageModule,
		SocketIoModule.forRoot(config),
	],
	providers: [
		Api,
		Users,
		Companies,
		Etages,
		News,
		PrivacyPolicy,
		Feedback,
		Chats,
		Bookings,
		StatusBar,
		SplashScreen,
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
		{ provide: Settings, useFactory: provideSettings, deps: [Storage] },
		GlobalProvider,
// 		FCM,
		Push,
		FormBuilder,
//		ImagePicker,
		Camera,
		Crop,
		FileTransfer,
		AppVersion,
		DatePipe,
		HTTP,
		InAppBrowser,
		Device,
	],
	bootstrap: [AppComponent]
})
export class AppModule {}
