import { Injectable } from '@angular/core';
import { GlobalProvider } from '../../providers/global/global';
import { Api } from '../api/api';

@Injectable()
export class Users {
	_user: any;
	constructor(
		public api: Api,
		public globalvar: GlobalProvider,
	) {}

	getusers(id) {
		let seq = this.api.get('companies').share();

		seq.subscribe(
			(res: any) => {
				// If the API returned a successful response, mark the project as logged in
			},
			(err) => {
				console.error('ERROR', err);
			}
		);

		return seq;
	}

	storePushkey(push_key, push_type, push_permission = true, user_id) {
		let seq = this.api
			.post('user/store/pushkey', {
				push_permission: push_permission,
				push_key: push_key,
				push_type: push_type,
				user_id: user_id,
			})
			.share();

		seq.subscribe(
			(res: any) => {
				// If the API returned a successful response, mark the project as logged in
			},
			(err) => {
				console.error('ERROR', err);
			}
		);

		return seq;
	}
}
