import { share } from 'rxjs/operators';
import {Observable} from 'rxjs/Rx';
import 'rxjs/add/operator/share'


import { Injectable } from '@angular/core';
import { GlobalProvider } from "../../providers/global/global";

import { Api } from '../api/api';

@Injectable()
export class Chats {
	_chats: any;
	
	constructor(
		public api: Api,
		public globalvar: GlobalProvider		
	) { }


	getAllChatsForUser(id) {
		let data = {
			'user_id' 	: id
		}
		let seq = this.api.post('chatgroups', data, null).share();
		
		seq.subscribe(
		(res: any) => {
			// If the API returned a successful response, mark the project as logged in

			
		},
		(err) => {
			console.error('ERROR', err);
		});
		
		return seq;		

	}


	getAllChatsForChatgroup(id, limit = null) {
		let data = {
			'chatgroup_id' 	: id,
			'limit': limit
		}
		let seq = this.api.post('chatgroups/chats', data, null).share();
		
		seq.subscribe(
		(res: any) => {
			// If the API returned a successful response, mark the project as logged in

			
		},
		(err) => {
			console.error('ERROR', err);
		});
		
		return seq;		

	}
	
	getAllParticipants(id){
		let seq = this.api.post('chatgroups/participants', {'chatgroup_id': id}, null).share();
		
		seq.subscribe(
		(res: any) => {
			// If the API returned a successful response, mark the project as logged in

			
		},
		(err) => {
			console.error('ERROR', err);
		});
		
		return seq;		
	}
	
	sendChat(data) {
		let seq = this.api.post('chatgroups/new/chat', data, null).share();
		
		seq.subscribe(
		(res: any) => {
			// If the API returned a successful response, mark the project as logged in

			
		},
		(err) => {
			console.error('ERROR', err);
		});
		
		return seq;		

	}


	remove(data){
		let seq = this.api.delete('chatgroups/chat/remove/'+data.id+'/'+data.owner_id).share();
		
		seq.subscribe(
		(res: any) => {
			// If the API returned a successful response, mark the project as logged in

			
		},
		(err) => {
			console.error('ERROR', err);
		});
		
		return seq;			
	}
	
	resetNotificationAmount(data){
		let seq = this.api.post('push/reset', data, null).share();
		
		seq.subscribe(
		(res: any) => {
			// If the API returned a successful response, mark the project as logged in

			
		},
		(err) => {
			console.error('ERROR', err);
		});
		
		return seq;	
	}


}
