import { Component, NgZone } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';

import {
	Platform,
	NavController,
	ToastController,
	AlertController,
} from '@ionic/angular';
import { Device } from '@ionic-native/device/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AuthService } from './services/auth.service';
import { AlertService } from './services/alert.service';
import { Users, Chats } from '../providers';
import { Storage } from '@ionic/storage';
import { GlobalProvider } from '../providers/global/global';
import { environment } from '../environments/environment';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

import { HTTP } from '@ionic-native/http/ngx';

import { Push, PushObject, PushOptions } from '@ionic-native/push/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss'],
})
export class AppComponent {
	public isAndroid: any;
	public firebaseConfig;

	constructor(
		private platform: Platform,
		private splashScreen: SplashScreen,
		private statusBar: StatusBar,
		//     private fcm: FCM,
		private authService: AuthService,
		private navCtrl: NavController,
		private alertService: AlertService,
		private users: Users,
		private storage: Storage,
		public globalvar: GlobalProvider,
		private router: Router,
		public toastController: ToastController,
		private push: Push,
		public chats: Chats,
		private zone: NgZone,
		private appVersion: AppVersion,
		public alertCtrl: AlertController,
		private http: HTTP,
		private iab: InAppBrowser,
		public device: Device
	) {
		//android
		document.addEventListener('message', (response: any) => {
			this.handleExpoListener(response);
		});
		//iOS
		window.addEventListener('message', (response: any) => {
			this.handleExpoListener(response);
		});

		this.firebaseConfig = this.globalvar.firebaseConfig;

		this.checkStorage();
	}

	async checkStorage() {
		await this.storage.get('demanufactuur_token').then((token) => {
			if (token !== '') {
				if (!this.checkIfValidJson(token)) {
					this.storage.set('demanufactuur_token', '');
					this.globalvar.demanufactuur_token = null;
				} else {
					this.globalvar.demanufactuur_token = JSON.parse(token);
				}
			}
		});

		await this.storage.get('user').then((user) => {
			if (user !== '') {
				if (!this.checkIfValidJson(user)) {
					this.storage.set('user', '');
					this.globalvar.myuser = null;
				} else {
					this.globalvar.myuser = JSON.parse(user);
				}
			}
		});

		await this.storage.get('mycompany').then((company) => {
			if (company !== '') {
				if (!this.checkIfValidJson(company)) {
					this.storage.set('mycompany', '');
					this.globalvar.mycompany = null;
				} else {
					this.globalvar.mycompany = JSON.parse(company);
				}
			}
		});

		await this.storage.get('demanufactuur_pushkey').then((key) => {
			if (key !== '') {
				if (!this.checkIfValidJson(key)) {
					this.storage.set('demanufactuur_pushkey', '');
					this.globalvar.pushkey = null;
				} else {
					this.globalvar.pushkey = JSON.parse(key);
				}
			}
		});

		this.init();
	}

	handleExpoListener(response) {
		if (response.data) {
			response = JSON.parse(response.data);

			if (response.type === 'config') {
				this.expoConfigUpdateHandler(response.data);
			}

			if (response.type === 'app-state') {
				if (response.state.match(/inactive|background/)) {
					// app is active again
					this.resumeEvent();
				}
			}

			if (response.type === 'back') {
				this.expoBackButtonHandler();
			}

			if (response.type === 'notification') {
				this.expoNotificationHandler(response.data);
			}
		}
	}

	expoConfigUpdateHandler(config) {
		Object.keys(config).forEach((key) => {
			// in order to have the push key once it is available
			if (key === 'deviceToken') {
				this.globalvar['pushkey'] = config[key];
				this.globalvar.pushNotificationEnabled = config['pushEnabled'] ? config['pushEnabled'] : 0;
				this.globalvar['deviceType'] = config['deviceType'];
				this.globalvar['pushType'] = config['deviceType'];
				this.storePushkey(config[key]);
			}

			if (key === 'deviceType') {
				this.globalvar['deviceType'] = config[key];
				this.globalvar['pushType'] = config[key];
			}

			if (key === 'applicationVersion') {
				this.globalvar['deploy'] = config[key];
				return;
			}

			this.globalvar[key] = config[key];
		});
	}

	expoBackButtonHandler() {
		if (
			this.router.url !== '/landing' &&
			this.router.url !== '/tabs/dashboard'
		) {
			window.history.back();
		} else {
			window['ReactNativeWebView'].postMessage(
				JSON.stringify({
					type: 'back',
					close: true,
				})
			);
		}
	}

	expoNotificationHandler(notification) {
		this.notificationReceived(notification);
	}

	init() {
		this.platform.ready().then(() => {
			this.statusBar.styleDefault();
			this.splashScreen.hide();
			this.authService.getToken();

			this.isAndroid = this.platform.is('android');

			this.getPushnotificationToken();
		});

		this.checkLoggedin();
	}

	resumeEvent() {
		window['ReactNativeWebView'].postMessage(
			JSON.stringify({ type: 'resumeEvent' })
		);

		if (this.globalvar.pushkey) {
			this.setup();
		}

		this.checkLoggedin();
	}

	checkLoggedin() {
		this.checkUpdate();
		if (
			this.authService.isLoggedIn &&
			this.authService.token.access_token
		) {
			this.authService.user().subscribe((user) => {
				this.globalvar.myuser = user;
				this.setChatNotificationBadge(this.globalvar.myuser.id);

				if (
					!this.globalvar.myuser.is_active ||
					this.globalvar.myuser.is_active == '0'
				) {
					this.alertService.presentToast('Uw acount is inactief');
					this.logout();
				} else {
					this.storage.set('user', JSON.stringify(user)).then(() => {
						this.storePushkey(this.globalvar.pushkey);
					});
				}
			});
		} else {
			this.authService.isLoggedIn = false;
			this.authService['token'] = { access_token: '' };
			this.navCtrl.navigateRoot('/landing');
		}
	}

	getPushnotificationToken() {
		this.setup();
	}

	setChatNotificationBadge(user_id) {
		this.chats.getAllChatsForUser(user_id).subscribe(
			(resp: any) => {
				let allChats = resp;
				let a = 0;
				this.globalvar.totalChats = 0;
				if (resp) {
					for (let x = 0; x < resp.length; x++) {
						if (resp[x].amount != null) {
							a += parseInt(resp[x].amount);
						}
					}
				}
				this.zone.run(() => {
					this.globalvar.totalChats = a;
				});
				this.globalvar.totalChats = a;
			},
			(err) => {}
		);
	}

	setup() {
		// to initialize push notifications
		const options: PushOptions = {
			android: {
				senderID: this.globalvar.firebaseConfig.messagingSenderId,
				// 		   forceShow: 'true', //visible push notification if app is open
				icon: 'icon', //THIS IS THE NAME OF THE ICON IN THE ANDROID DRAWABLES
				sound: true,
				// 		   clearNotifications: true
			},
			ios: {
				alert: 'true',
				badge: true,
				sound: true,
				clearBadge: true,
			},
		};

		setTimeout(() => {
			const pushObject: PushObject = this.push.init(options);

			pushObject.on('registration').subscribe((registration: any) => {
				this.storage.set(
					'demanufactuur_pushkey',
					JSON.stringify(registration.registrationId)
				);
				this.globalvar.pushkey = registration.registrationId;
				this.storePushkey(registration.registrationId);
			});

			pushObject
				.on('notification')
				.subscribe(async (notification: any) => {
					console.log('Received a notification', notification);
					this.notificationReceived(notification);
				});

			pushObject.on('error').subscribe((error) => {
				console.error('Error with Push plugin', error);
			});
		}, 1000);
	}

	getMobileOperatingSystem() {
		if (this.device.platform) {
			return this.device.platform;
		} else if (this.globalvar.deviceType) {
			return this.globalvar.deviceType;
		} else {
			return '';
		}
	}

	async storePushkey(token) {
		let type = this.getMobileOperatingSystem();
		this.globalvar.pushType = type;

		await this.storage.get('user').then(
			(user) => {
				//TODO 1 must be push_permission
				user = JSON.parse(user);
				this.users
					.storePushkey(token, type, this.globalvar.pushNotificationEnabled, user.id)
					.subscribe(
						(resp: any) => {},
						(err) => {}
					);
			},
			(error: any) => {
				console.error(JSON.stringify(error));
			}
		);
	}

	openPage(page) {
		this.navCtrl.navigateRoot(page);
	}

	logout() {
		this.authService.logout().subscribe(
			(data) => {
				this.users
					.storePushkey('', '', false, this.globalvar.myuser.id)
					.subscribe(
						(resp: any) => {},
						(err) => {}
					);
				this.globalvar.myuser = {};
				this.storage.clear();
				this.authService.isLoggedIn = false;
				this.navCtrl.navigateRoot('/landing');
			},
			(error) => {
				this.users
					.storePushkey('', '', false, this.globalvar.myuser.id)
					.subscribe(
						(resp: any) => {},
						(err) => {}
					);
				console.log(error);
				this.globalvar.myuser = {};
				this.storage.clear();
				this.authService.isLoggedIn = false;
				this.navCtrl.navigateRoot('/landing');
			}
		);
	}

	async notificationReceived(notification) {
		this.chats
			.getAllChatsForUser(this.globalvar.myuser.id)
			.subscribe((resp: any) => {
				let a = 0;
				this.globalvar.totalChats = 0;
				if (resp) {
					for (let x = 0; x < resp.length; x++) {
						if (resp[x].amount != null) {
							a += parseInt(resp[x].amount);
						}
					}
				}
				this.zone.run(() => {
					this.globalvar.totalChats = a;
				});
				this.globalvar.totalChats = a;
			});

		let room_id = notification.additionalData.room
			? notification.additionalData.room
			: notification.additionalData['gcm.notification.room'];
		if (!notification.additionalData.foreground) {
			let typ = notification.additionalData.type
				? notification.additionalData.type
				: notification.additionalData['gcm.notification.type'];

			if (typ == 'chatmessage') {
				await this.storage.get('user').then(
					(user) => {
						user = JSON.parse(user);
						let navigationExtras: NavigationExtras = {
							queryParams: {
								key: room_id,
								title: notification.title,
								usersKey: user.id,
								username: user.name,
								avatar: user.avatar,
								parent: this,
							},
						};

						// 		this.router.navigate(['chatroom'], navigationExtras);
						this.navCtrl.navigateForward(
							'chatroom',
							navigationExtras
						);
					},
					(error: any) => {
						console.error(JSON.stringify(error));
					}
				);
			}
		}

		if (this.globalvar.currentChat != room_id) {
			this.presentToastWithOptions(notification);
		}
	}

	async presentToastWithOptions(data) {
		const toast = await this.toastController.create({
			header: data.title,
			message: data.message,
			//       message: data.body,
			duration: 5000,
			position: 'top',
			buttons: [
				{
					text: 'Sluit',
					role: 'cancel',
					handler: () => {
						console.log('Cancel clicked');
					},
				},
			],
		});
		toast.present();
	}

	checkUpdate() {
		var deviceVersionLookup = null;
		var appversion = this.globalvar.deploy;

		this.http
			.get(`${environment.updateURL}`, {}, {})
			.then((res) => {
				var data = JSON.parse(res.data);

				if (this.isAndroid) {
					deviceVersionLookup = data.androidVersion;
				} else {
					deviceVersionLookup = data.iosVersion;
				}
				var shouldUpdate = this.globalvar.versionComparision(
					deviceVersionLookup,
					appversion
				);
				if (shouldUpdate == 1) {
					this.versionPopup();
				}
			})
			.catch((error) => {});
	}

	async versionPopup() {
		const alert = await this.alertCtrl.create({
			header: 'UPDATE beschikbaar',
			message:
				'Er is een nieuwe versie van de App beschikbaar. Om gebruik te maken van alle functionaliteiten is het noodzakelijk deze versie te installeren',
			buttons: [
				{
					text: 'Cancel',
					role: 'cancel',
					handler: (data) => {
						console.log('Cancel clicked');
					},
				},
				{
					text: 'Update nu',
					role: 'save',
					handler: (data) => {
						if (this.isAndroid) {
							this.iab.create(
								`${environment.playstore}`,
								'_system'
							);
						} else {
							this.iab.create(`${environment.appstore}`, '_self');
						}
					},
				},
			],
		});
		await alert.present();
	}

	checkIfValidJson(str) {
		try {
			JSON.parse(str);
		} catch (e) {
			return false;
		}
		return true;
	}
}
