import { share } from 'rxjs/operators';
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/operator/share';

import { Storage } from '@ionic/storage';
import { Injectable } from '@angular/core';
import { GlobalProvider } from '../../providers/global/global';

import { Api } from '../api/api';

@Injectable()
export class Companies {
	_company: any;

	constructor(
		public api: Api,
		public globalvar: GlobalProvider,
		private storage: Storage
	) {}

	getCompany(user) {
		let seq = this.api.get('company/user/' + user).share();

		seq.subscribe(
			async (mycompany: any) => {
				// If the API returned a successful response, mark the project as logged in
				// If the API returned a successful response, mark the project as logged in

				await this.storage
					.set('mycompany', JSON.stringify(mycompany))
					.then(
						() => {
							this.globalvar.mycompany = mycompany;
						},
						(error) => console.error('Error storing item', error)
					);
			},
			(err) => {
				console.error('ERROR', err);
			}
		);

		return seq;
	}

	getCompanies() {
		let seq = this.api.get('companies').share();

		seq.subscribe(
			async (res: any) => {
				// If the API returned a successful response, mark the project as logged in
				await this.storage
					.set('companies', JSON.stringify(res.data))
					.then(
						() => {
						},
						(error) => {
							this.storage.set(
								'companies',
								JSON.stringify(res.data)
							);
							console.error('Error storing item', error);
						}
					);
			},
			(err) => {
				console.error('ERROR', err);
			}
		);

		return seq;
	}

	getCollegas(id) {
		let seq = this.api.get('companies/collegas').share();

		seq.subscribe(
			(res: any) => {
				// If the API returned a successful response, mark the project as logged in
			},
			(err) => {
				console.error('ERROR', err);
			}
		);

		return seq;
	}

	setCollegas(data) {
		let seq = this.api.post('newuser', data, null).share();

		seq.subscribe(
			(res: any) => {
				// If the API returned a successful response, mark the project as logged in
			},
			(err) => {
				console.error('ERROR', err);
			}
		);

		return seq;
	}

	setPush(data) {
		let seq = this.api.post('user/store/pushon', data, null).share();

		seq.subscribe(
			(res: any) => {
				// If the API returned a successful response, mark the project as logged in
			},
			(err) => {
				console.error('ERROR', err);
			}
		);

		return seq;
	}

	deleteCollegas(data) {
		let seq = this.api.delete('user/delete/' + data.id).share();

		seq.subscribe(
			(res: any) => {
				// If the API returned a successful response, mark the project as logged in
			},
			(err) => {
				console.error('ERROR', err);
			}
		);

		return seq;
	}

	deleteAvatar(data) {
		let seq = this.api.post('user/delete/avatar', data, null).share();

		seq.subscribe(
			(res: any) => {
				// If the API returned a successful response, mark the project as logged in
			},
			(err) => {
				console.error('ERROR', err);
			}
		);

		return seq;
	}

	getMyCompanies(data) {
		let seq = this.api.post('company/my', data, null).share();

		seq.subscribe(
			(res: any) => {
				// If the API returned a successful response, mark the project as logged in
			},
			(err) => {
				console.error('ERROR', err);
			}
		);

		return seq;
	}
}
