import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TabsPage } from './tabs.page';


const routes: Routes = [
  {
    path: 'tabs',
    component: TabsPage,
    children: [
      {
        path: 'dashboard',
        children: [
          {
            path: '',
            loadChildren: () =>
              import('../pages/dashboard/dashboard.module').then(m => m.DashboardPageModule)
          }
        ]
      },
      {
        path: 'bookings',
        children: [
          {
            path: '',
            loadChildren: () =>
              import('../bookings/bookings.module').then(m => m.BookingsPageModule)
          }
        ]
      },
      {
        path: 'mybookings',
        children: [
          {
            path: '',
            loadChildren: () =>
              import('../mybookings/mybookings.module').then(m => m.MybookingsPageModule)
          }
        ]
      },
      {
        path: 'collegas',
        children: [
          {
            path: '',
            loadChildren: () =>
              import('../collegas/collegas.module').then(m => m.CollegasPageModule)
          }
        ]
      },
      {
        path: 'chats',
        children: [
          {
            path: '',
            loadChildren: () =>
              import('../chats/chats.module').then(m => m.ChatsPageModule)
          }
        ]
      },
      {
        path: 'rooms',
        children: [
          {
            path: '',
            loadChildren: () =>
              import('../rooms/rooms.module').then(m => m.RoomsPageModule)
          }
        ]
      },
      {
        path: 'settings',
        children: [
          {
            path: '',
            loadChildren: () =>
              import('../settings/settings.module').then(m => m.SettingsPageModule)
          }
        ]
      },
      {
        path: 'news',
        children: [
          {
            path: '',
            loadChildren: () =>
              import('../news/news.module').then(m => m.NewsPageModule)
          }
        ]
      },  
      {
        path: 'privacy-policy',
        children: [
          {
            path: '',
            loadChildren: () =>
              import('../privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyPageModule)
          }
        ]
      } ,
      {
        path: 'feedback',
        children: [
          {
            path: '',
            loadChildren: () =>
              import('../feedback/feedback.module').then(m => m.FeedbackPageModule)
          }
        ]
      }          
    ]
  },
  {
    path: '',
    redirectTo: '/tabs/collegas',
    pathMatch: 'full'
  }
];

@NgModule({

})
export class TabsPageRoutingModule {}
