import { Component, OnInit } from '@angular/core';
import { NavParams, NavController } from '@ionic/angular';
import { Platform, ModalController, ToastController } from '@ionic/angular';
import { GlobalProvider } from '../../providers/global/global';
import { Bookings, Companies } from '../../providers';
import { Socket } from 'ngx-socket-io';
import { MomentjsPipe } from '../../pipes/momentjs/momentjs';

@Component({
	selector: 'app-bookingresult-modal',
	templateUrl: './bookingresult-modal.page.html',
	styleUrls: ['./bookingresult-modal.page.scss'],
})
export class BookingresultModalPage implements OnInit {
	booking: any;
	message: any;

	constructor(
		private platform: Platform,
		public modalCtrl: ModalController,
		private navParams: NavParams,
		public globalvar: GlobalProvider,
		public bookings: Bookings,
		public companies: Companies,
		private socket: Socket,
		public momentjs: MomentjsPipe,
		public toastController: ToastController,
		public navCtrl: NavController
	) {
		this.booking = navParams.get('booking');
		this.message = navParams.get('message');
	}

	ngOnInit() {}

	dismissModal() {
		// using the injected ModalController this page
		// can "dismiss" itself and optionally pass back data
		this.modalCtrl.dismiss({
			dismissed: true,
		});
	}

	openBookings() {
		this.navCtrl.navigateForward('tabs/mybookings');
		this.dismissModal();
	}
}
