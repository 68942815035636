import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import 'moment/src/locale/nl';

/**
 * Generated class for the MomentjsPipe pipe.
 *
 * See https://angular.io/docs/ts/latest/guide/pipes.html for more info on
 * Angular Pipes.
 */
@Pipe({
	name: 'momentjs',
})
export class MomentjsPipe implements PipeTransform {
	/**
	 * Takes a date value and returns a pretty string from current time,
	 * for instance: "four hours ago" or "in eleven minutes".
	 */
	transform(value: string, ...args) {
		moment.locale('nl');
		return moment(value).format(args[0]);
		//return value.toLowerCase();
	}
}
