import { Injectable } from '@angular/core';
import { GlobalProvider } from '../../providers/global/global';

@Injectable({
	providedIn: 'root',
})
export class EnvService {
	API_URL = 'http://127.0.0.1:8000/api/';

	constructor(public globalvar: GlobalProvider) {
		this.API_URL = globalvar.login;
	}
}
