import { share } from 'rxjs/operators';
import {Observable} from 'rxjs/Rx';
import 'rxjs/add/operator/share'

import { Storage } from '@ionic/storage';
import { Injectable } from '@angular/core';
import { GlobalProvider } from "../../providers/global/global";

import { Api } from '../api/api';

@Injectable()
export class Etages {
	_Etage: any;
	

	
	constructor(
		public api: Api,
		private storage: Storage,
		public globalvar: GlobalProvider		
	) { }


	getEtages() {
		let seq = this.api.get('etages').share();
		
		seq.subscribe(
		async (res: any) => {
			// If the API returned a successful response, mark the project as logged in
			// If the API returned a successful response, mark the project as logged in
			await this.storage.set('etages', JSON.stringify(res))
	        .then(
	          () => {
	            console.log('etages Stored');
	          },
	          error => {
				  this.storage.set('etages', JSON.stringify(res));
		          console.error('Error storing item', error)
		      }
	        );		
			
		},
		(err) => {
			console.error('ERROR', err);
		});
		
		return seq;
	}



}
