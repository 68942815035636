export { Settings } from './settings/settings';
export { Api } from './api/api';
export { Users } from './users/users';
export { Companies } from './companies/companies';
export { Etages } from './etages/etages';
export { News } from './news/news';
export { Chats } from './chats/chats';
export { Bookings } from './bookings/bookings'
export { PrivacyPolicy } from './privacy-policy/privacy-policy'
export { Feedback } from './feedback/feedback'
