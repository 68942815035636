import { Component, OnInit, NgZone } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { ModalController,AlertController } from '@ionic/angular';
import { GlobalProvider } from "../../providers/global/global";
import { Companies} from '../../providers';
import { Crop } from '@ionic-native/crop/ngx';
//import { ImagePicker } from '@ionic-native/image-picker/ngx';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { FileTransfer, FileUploadOptions, FileTransferObject } from '@ionic-native/file-transfer/ngx';
import { Storage } from '@ionic/storage';
import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-collega-modal',
  templateUrl: './collega-modal.page.html',
  styleUrls: ['./collega-modal.page.scss'],
})


export class CollegaModalPage implements OnInit {

	page = 'Collega\'s';
	userinfo: any ={'id':'','avatar': '', 'avatar_tmp': ''};
	edituser: any = false;
	itsme: any = false;	
	myuser: any={'id':'','avatar': '',  'avatar_tmp': ''};
	mycompany:any ={'id':''};
	fileUrl: any = null;
	respData: any;
	private useredit : any;
	url: string = '';
	downloadProgress: any;
	
	new_image: any;

	tmp_image_to_remove: any;
	isSaving: any = false;

	constructor(
		private modalCtrl: ModalController,
		private navParams: NavParams,
		private storage: Storage,
		private camera: Camera,
		private crop: Crop,
		private transfer: FileTransfer,
		private alertService: AlertService,
		private authService: AuthService,
		public	globalvar: GlobalProvider,
		public	companies: Companies,
// 		private imagePicker: ImagePicker,
		public alertController: AlertController,
		public ngZone: NgZone,
	) {
		this.userinfo = navParams.get('user');
		this.userinfo.companies_ids = [];
// 		 	this.userinfo.avatar_tmp = this.userinfo.avatar;
		 for(let x=0;x<this.userinfo.companies.length;x++){
			 this.userinfo.companies_ids.push(this.userinfo.companies[x].id);
		 }
		 if(this.userinfo.id == 0){
			this.edituser = true;			 
		 }
		 
		 this.url = this.globalvar.api;
	}

	async prepareData() {
		await this.storage.get('user')
			.then(
				data => {
					data = JSON.parse(data);
// 					this.userinfo.avatar_tmp = '';
					this.myuser = data;
					if(this.userinfo.id == this.myuser.id){
						this.edituser = true;
// 						this.userinfo.avatar_tmp = this.userinfo.avatar;
					}
				}
			);
		await this.storage.get('mycompany')
			.then(
				data => {
					data = JSON.parse(data);
					this.mycompany = data;
				}
			);
	}

	editUser(userinfo){
		let edit = false;
		this.itsme = false;
		if(!userinfo.id){
			edit = true;
// 			userinfo.avatar_tmp = userinfo.avatar;
			this.page = 'Collega\'s';
		}else if (userinfo.id == this.myuser.id){
			edit = true;
			this.page = 'Profiel';
// 			userinfo.avatar_tmp = userinfo.avatar;	
			this.itsme = true;	
// 		}else if (userinfo.companies_ids.includes(this.mycompany.id) && this.myuser.role_id < 4){
		}else if (userinfo.companies_ids.includes(this.mycompany.id.toString()) && this.myuser.role_id < 4){
			edit = true;
			this.page = 'Collega\'s';
// 			userinfo.avatar_tmp = userinfo.avatar;
		}
		return edit;		
	}
	

		
	async deleteCollega() {
	    const alert = await this.alertController.create({
	      header: 'Verwijderen!',
	      message: 'De gebruiker wordt verwijderd!!!',
	      buttons: [
	        {
	          text: 'Annuleer',
	          role: 'cancel',
	          cssClass: 'secondary',
	          handler: (blah) => {
	            console.log('Confirm Cancel');
	          }
	        }, {
	          text: 'Oké',
	          handler: () => {
	            this.companies.deleteCollegas(this.userinfo).subscribe((resp:any) => {
					this.dismissModal();
		
				});
	          }
	        }
	      ]
	    });
	
	    await alert.present();
	  }
		
		

	saveCollega(){
		let avatar = (<HTMLInputElement>document.getElementById('avatar2')).getAttribute("src");
		
		avatar = 'users/'+avatar.substr(avatar.lastIndexOf('/') + 1);
		
		if(!this.userinfo.name){
			this.alertService.presentToast("Naam is verplicht");
			return;
		}
		if(!this.userinfo.email){
			this.alertService.presentToast("Email is verplicht");
			return;
		}
		
		let data = {
			id: this.userinfo.id,
			name: this.userinfo.name,
			email: this.userinfo.email,
			phone: this.userinfo.phone,
			gender: this.userinfo.gender,
			function: this.userinfo.function,
			role_id: this.userinfo.role_id || 4,
			company_id: this.mycompany.id,
			avatar: avatar,
		};
		this.companies.setCollegas(data).subscribe((resp:any) => {
			let user_id = 0;
			if(this.userinfo){
				user_id = this.userinfo.id;
				this.userinfo.avatar = resp.avatar;
				this.userinfo.gender = resp.gender;
				this.userinfo.phone = resp.phone;
				this.userinfo.function = resp.function;
				this.userinfo.email = resp.email;
			}
// 			this.userinfo.avatar_tmp = '';
/*
			let imgtag = (<HTMLInputElement>document.getElementById('avatar'));
			imgtag.src= this.globalvar.storage+resp.avatar;
*/
			if(this.itsme){
				this.authService.user().subscribe(
				user => {
					this.storage.set('user', JSON.stringify(user))
					  .then(
					    () => {}
					  );
					this.globalvar.myuser = user;
	
				}
			);
			}
		},
		(err) => {
	
		});	
	  
	  this.isSaving = true;
	  this.dismissModal();
 
 	 }
 	 
	ngOnInit() {
	}
	cropUpload() {
		var that = this;
		const options: CameraOptions = {
			quality: 50,
			destinationType: this.camera.DestinationType.FILE_URI,
			encodingType: this.camera.EncodingType.JPEG,
			mediaType: this.camera.MediaType.PICTURE,
			sourceType: this.camera.PictureSourceType.PHOTOLIBRARY
		}		
		
		this.camera.getPicture(options).then((imageData) => {
 // imageData is either a base64 encoded string or a file URI
 // If it's base64 (DATA_URL):
			let base64Image = 'data:image/jpeg;base64,' + imageData;
			this.crop.crop(imageData, { quality: 100 })
			.then(
				newImage => {
					let user_id = 0;
					if(this.userinfo){
						user_id = this.userinfo.id;
					}
					this.new_image = newImage;
					const fileTransfer: FileTransferObject = this.transfer.create();
					const uploadOpts: FileUploadOptions = {
						fileKey: 'image',
						fileName: newImage.substr(newImage.lastIndexOf('/') + 1),
					};
					var params = {
						user_id : user_id,
					}
					uploadOpts.params = params;
	
					fileTransfer.onProgress((progressEvent) => {
						if (progressEvent.lengthComputable) {
							var perc = Math.floor(progressEvent.loaded / progressEvent.total * 100);
							document.getElementById('loading_perc').innerHTML = perc+'%';
							if(perc >= 99){
								document.getElementById('loading_perc').innerHTML = '...verwerken';
							}
						} else {
							document.getElementById('loading_perc').innerHTML = '';
						}
					});
					fileTransfer.upload(this.new_image, this.url+'/user/store/avatar', uploadOpts)
					.then((data) => {
						let avatar_file = JSON.parse(data.response);
						let imgtag = (<HTMLInputElement>document.getElementById('avatar2'));
						imgtag.src= this.globalvar.storage+avatar_file;
						that.tmp_image_to_remove = avatar_file;
						
						
						document.getElementById('loading_perc').innerHTML = 'Geüpload';
					}, (err) => {
						console.log(err);
					});
				},
				error => console.error('Error cropping image', error)
			);

		});
	}

  dismissModal() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    var imageToDelete;
    if(this.isSaving && this.tmp_image_to_remove){
	    imageToDelete = this.userinfo.avatar;
	    if(this.itsme){
	    	this.globalvar.myuser.avatar = this.tmp_image_to_remove;
	    }
    }else{
	    imageToDelete = this.tmp_image_to_remove;
		if(this.itsme){
	    	this.globalvar.myuser.avatar = this.userinfo.avatar;
	    }
    }
    
    
    this.modalCtrl.dismiss({
      'dismissed': true,
      'avatar_tmp': imageToDelete 
      
    });
  }

}
